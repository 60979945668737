import { useEffect, useState } from "react"
import Sidebar from "../components/Sidebar"
import { useParams, useSearchParams } from "react-router-dom"
import axios from "axios"
import { useAuth } from "../utils/auth"
import config from "../config/config"
import { saveAs } from "file-saver"
import { FaFlag, FaClock, FaUser, FaEnvelope, FaFileAlt } from "react-icons/fa"

const CAMPAIGNRECORDDETAILURL = `${config.apiBaseUrl}dashboard/startpage`
const DEVICESURL = `${config.apiBaseUrl}dashboard/devices`
const ITEMS_PER_PAGE = 10

function CampaignRecords() {
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const auth = useAuth()
  const [campaignDetails, setCampaignDetails] = useState()
  const [adminName, setAdminName] = useState()
  const [adminMail, setAdminMail] = useState()
  const [sentDevices, setSentDevices] = useState(0)
  const [readDevices, setReadDevices] = useState([])
  const [phishedDevices, setPhishedDevices] = useState([])
  const [currentPage, setCurrentPage] = useState(Number.parseInt(searchParams.get("page") || "1"))
  const [totalPages, setTotalPages] = useState(1)
  const [chunkId, setChunkId] = useState(1)

  // useEffect(() => {
    
  // }, [third])
  
  // const fetchAdmin = async () => {
  //   try {
  //     const res = await axios({
  //       method: "GET",
  //       url: GET_ADMIN,
  //       headers: {
  //         authorization: auth.user.token,
  //       },
  //     });
  //     const data = res.data;
  //     if (data) {
  //       console.log(`Admin data - ${JSON.stringify(data)}`)
  //       setAdmin(data);
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  useEffect(() => {
    if (campaignDetails?.campaignUsers) {
      setTotalPages(Math.ceil(campaignDetails.campaignUsers.length / ITEMS_PER_PAGE))
      setSentDevices(campaignDetails.campaignUsers.length)
    }
  }, [campaignDetails])

  useEffect(() => {
    setSearchParams({ page: currentPage.toString() })
  }, [currentPage, setSearchParams])

  useEffect(() => {
    async function fetchData() {
      const data = {
        id: `${auth.user.id}`,
        // chunkId: `${1}`,
        campaign_id: `${id}`,
      }
      try {
        const response = await axios.get(CAMPAIGNRECORDDETAILURL, {
          params: data,
          headers: { authorization: auth.user.token },
        })
        setCampaignDetails(response.data)
      } catch (err) {
        console.error("Error:", err)
      }
    }

    fetchData()
  }, [])

  const getCurrentPageData = () => {
    if (!campaignDetails?.campaignUsers) return []
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
    return campaignDetails.campaignUsers.slice(startIndex, startIndex + ITEMS_PER_PAGE)
  }

  useEffect(() => {
    async function fetchData() {
      const data = {
        id: `${auth.user.id}`,
        chunkId: `${chunkId}`,
        campaign_id: `${id}`,
      }
      try {
        const response = await axios.get(DEVICESURL, {
          params: data,
          headers: { authorization: auth.user.token },
        })
        setPhishedDevices(response.data.phishedDevices)
        setReadDevices(response.data.readDevice)
      } catch (err) {
        console.error("Error:", err)
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${config.apiBaseUrl}get/admin`, {
          headers: { authorization: auth.user.token },
        })
        setAdminName(response.data.name)
        setAdminMail(response.data.workEmail)
      } catch (err) {
        console.error("Error:", err)
      }
    }

    fetchData()
  }, [])

  const exportData = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}generate/excel?campaign_id=${id}`, {
        headers: { authorization: auth.user.token },
        responseType: "blob",
      })
      saveAs(response.data, "generated_excel.xlsx")
    } catch (err) {
      console.error("Error:", err)
    }
  }

  const exportPDF = async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}generate/pdf?campaign_id=${id}`, {
        headers: { authorization: auth.user.token },
        responseType: "blob",
      })
      saveAs(response.data, "generated_pdf.pdf")
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <div className="flex">
        <div className="w-60">
          <Sidebar />
        </div>
        <div className="flex flex-grow relative flex-col">
          <div id="PageContent" className="pt-30 mt-[81.5px]">
            <div id="PageContent" className="overflow-y-auto overflow-x-hidden ">
              <div className="flex p-3 space-x-1 items-center">
                <button
                  onClick={() => {
                    exportPDF()
                  }}
                  className="ml-auto focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900"
                >
                  Export PDF Report
                </button>
                <button
                  onClick={() => {
                    exportData()
                  }}
                  className="ml-auto focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900"
                >
                  Export Excel Report
                </button>
              </div>
              <div className="p-5">
                <h1 className="text-2xl text-center font-bold text-gray-700">Campaign Statistics</h1>
                <div className="h-0.5 my-3 bg-gray-700 rounded dark:bg-gray-700" />
                {/* <div className="flex p-3 my-10 justify-center">
                  <RadialChartRecords campaign_id={id} />
                  {readDevices && readDevices.length > 0 ? <PieChart Devices={readDevices} Type={"Opened"} /> : null}
                  {phishedDevices && phishedDevices.length > 0 ? (
                    <PieChart Devices={phishedDevices} Type={"Clicked"} />
                  ) : null}
                </div> */}
              </div>
              <div className="stats stats-vertical lg:stats-horizontal flex justify-center mx-8">
                <div className="stat text-right">
                  <div className="stat-title text-[#3e70b2]">Sent</div>
                  <div className="stat-value text-[#3e70b2]">{sentDevices}</div>
                  {/* <div className="stat-desc">Jan 1st - Feb 1st</div> */}
                </div>

                <div className="stat text-center">
                  <div className="stat-title text-[#319a67]">Opened</div>
                  <div className="stat-value text-[#319a67]">{readDevices.length}</div>
                  {/* <div className="stat-desc">↗︎ 400 (22%)</div> */}
                </div>

                <div className="stat text-left">
                  <div className="stat-title text-[#d49530]">Phished</div>
                  <div className="stat-value text-[#d49530]">{phishedDevices.length}</div>
                  {/* <div className="stat-desc">↘︎ 90 (14%)</div> */}
                </div>
              </div>
              <div className="px-5 mt-6">
                <h1 className="text-2xl text-center font-bold text-gray-700">Campaign Details</h1>
                <div className="h-0.5 my-3 bg-gray-700 rounded dark:bg-gray-700" />
                <div className="flex flex-wrap justify-center gap-8 mt-6">
                  <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-xl flex items-center justify-center">
                    <div className="grid grid-cols-2 gap-4 items-center justify-center">
                      <div className="flex justify-center items-center space-x-3 p-6">
                        <FaFlag className="text-2xl text-gray-600" />
                        <div>
                          <div className="font-semibold">{campaignDetails ? campaignDetails.campaignName : ""}</div>
                          <div className="text-sm text-gray-500">Campaign Name</div>
                        </div>
                      </div>
                      <div className="flex justify-center items-center space-x-3 p-6">
                        <FaClock className="text-2xl text-gray-600" />
                        <div>
                          <div className="font-semibold">
                            {campaignDetails ? campaignDetails.campaignTimestamp.split("T")[0] : ""}
                          </div>
                          <div className="text-sm text-gray-500">Campaign Timestamp</div>
                        </div>
                      </div>
                      <div className="flex justify-center items-center space-x-3 p-6">
                        <FaUser className="text-2xl text-gray-600" />
                        <div>
                          <div className="font-semibold">{adminName}</div>
                          <div className="text-sm text-gray-500">Sender name</div>
                        </div>
                      </div>
                      <div className="flex justify-center items-center space-x-3 p-6">
                        <FaEnvelope className="text-2xl text-gray-600" />
                        <div>
                          <div className="font-semibold">
                            {campaignDetails ? campaignDetails.campaignEnvelopeEmailId : ""}
                            {/* {adminMail ? adminMail : ""} */}
                          </div>
                          <div className="text-sm text-gray-500 ">Sender email address</div>
                        </div>
                      </div>
                      <div className="flex items-center justify-center space-x-3 col-span-2 p-4">
                        <FaFileAlt className="text-2xl text-gray-600" />
                        <div>
                          <div className="font-semibold">{campaignDetails ? campaignDetails.campaignSubject : ""}</div>
                          <div className="text-sm text-gray-500">Email subject</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-md">
                    <div className="thumbnail-container rounded-lg overflow-hidden mb-4">
                      {campaignDetails && (
                        <img
                          src={`data:image/png;base64,${campaignDetails.imageData}`}
                          alt="template"
                          className="w-full h-auto object-cover"
                        />
                      )}
                    </div>
                    <h5 className="text-xl font-semibold mb-2">
                      {campaignDetails ? campaignDetails.templateName : ""}
                    </h5>
                    <p className="text-gray-500">Template</p>
                  </div>
                </div>
              </div>
              <div className="p-5">
                <h1 className="text-2xl text-center font-bold text-gray-700">User Details</h1>
                <div className="h-0.5 my-3 bg-gray-700 rounded dark:bg-gray-700" />
                <table className="w-full text-sm text-left text-gray-500 m-2 p-2">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr className="text-center">
                      <th scope="col" className="px-4 py-3 border-r border-b border-l">
                        Name
                      </th>
                      <th scope="col" className="px-4 py-3 border-r border-b">
                        Email
                      </th>
                      <th scope="col" className="px-4 py-3 border-r border-b">
                        Department
                      </th>
                      <th scope="col" className="px-4 py-3 border-r border-b">
                        Location
                      </th>
                      <th scope="col" className="px-4 py-3 border-r border-b">
                        Opened Device
                      </th>
                      <th scope="col" className="px-4 py-3 border-r border-b">
                        Clicked Device
                      </th>
                      <th scope="col" className="px-4 py-3 bg-[#7cb4fc] border-r border-b">
                        Sent
                      </th>
                      <th scope="col" className="px-4 py-3 bg-[#64e4a4] border-r border-b">
                        Opened
                      </th>
                      <th scope="col" className="px-4 py-3 bg-[#f4bc54] border-r border-b">
                        Phished
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getCurrentPageData().map((curUser) => (
                      <tr key={curUser.id} className="bg-white border-b hover:bg-gray-100 text-center">
                        <td className="px-3 py-4 border-r border-l">{curUser.name}</td>
                        <td className="px-3 py-4 border-r">{curUser.email}</td>
                        <td className="px-3 py-4 border-r">{curUser.department}</td>
                        <td className="px-3 py-4 border-r">{curUser.location}</td>
                        <td className="px-3 py-4 border-r">
                          {curUser.openedDevice === null ? "None" : curUser.openedDevice}
                        </td>
                        <td className="px-3 py-4 border-r">
                          {curUser.clickedDevice === null ? "None" : curUser.clickedDevice}
                        </td>
                        <td className="px-3 py-4 border-r">{curUser.sent === 1 ? "✅" : "❌"}</td>
                        <td className="px-3 py-4 border-r">{curUser.opened === 1 ? "✅" : "❌"}</td>
                        <td className="px-3 py-4 border-r">{curUser.clicked === 1 ? "✅" : "❌"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="join flex justify-center mb-8">
                {[...Array(totalPages)].map((_, index) => (
                  <input
                    key={index + 1}
                    className={`join-item btn btn-square rounded-lg ${currentPage === index + 1 ? "bg-blue-200 text-white" : ""}`}
                    type="radio"
                    name="options"
                    aria-label={index + 1}
                    checked={currentPage === index + 1}
                    onChange={() => setCurrentPage(index + 1)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CampaignRecords

