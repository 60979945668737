import { useEffect, useState } from "react"
import { useAuth } from "../utils/auth"
import { ToastContainer, toast } from "react-toastify"
import RadialChart from "../components/RadialChart"
import LineChart from "../components/LineChart"
import Sidebar from "../components/Sidebar"
import Dropdown from "../components/DropDown"
import axios from "axios"
import config from "../config/config"

const STATS_URL = `${config.apiBaseUrl}dashboard/stats`

function Charts() {
  const auth = useAuth()
  const [lineDropdown, setLineDropdown] = useState(0)
  const [sent, setSent] = useState(0)
  const [clicked, setClicked] = useState(0)
  const [phished, setPhished] = useState(0)

  const maplineDropDown = {
    0: 7,
    1: 30,
    2: 90,
  }

  useEffect(() => {
    const timeRange = { days: `${maplineDropDown[lineDropdown]}` }
    async function fetchStats() {
      await axios.get(STATS_URL, {
        params: timeRange,
        headers: { authorization: auth.user.token },
      }).then((response) => {
        if (response.status === 200) {
          setSent(response.data.totalSent)
          setClicked(response.data.totalClicked)
          setPhished(response.data.totalOpened)
        }
      }) 
      .catch((err)=> {
        toast.error(`Please login to continue.`)
      })
    }
    fetchStats()
  }, [lineDropdown])

  return (
    <div className="flex">
      <div className="w-1/6">
        {" "}
        {/* Adjust the width as needed */}
        <Sidebar />
      </div>

      <div className="flex flex-grow relative flex-col">
        <div className="py-0 mt-10 mx-10 justify-between">
          <h5 className="my-4 text-2xl font-bold text-gray-700">Campaign Statistics</h5>
          <div className="flex justify-center items-center h-1/2">
            <div className="flex justify-between items-center space-x-4 mx-8">
              <div className="card w-64 bg-base-100 shadow-xl">
                <div className="card-body">
                  <h2 className="card-title text-[#5a8fd6]">Sent</h2>
                  <p className="text-4xl font-bold text-[#5a8fd6]">{sent}</p>
                </div>
              </div>
              <div className="card w-64 bg-base-100 shadow-xl">
                <div className="card-body">
                  <h2 className="card-title text-[#4bc389]">Opened</h2>
                  <p className="text-4xl font-bold text-[#4bc389]">{clicked}</p>
                </div>
              </div>
              <div className="card w-64 bg-base-100 shadow-xl">
                <div className="card-body">
                  <h2 className="card-title text-[#e6a43c]">Phished</h2>
                  <p className="text-4xl font-bold text-[#e6a43c]">{phished}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center">
            <Dropdown
              labels={{
                0: "Last 7 days",
                1: "Last 30 days",
                2: "Last 90 days",
              }}
              option={lineDropdown}
              setOption={setLineDropdown}
            />
          </div>
        </div>

        <div id="PageContent" className="pt-30">
          <div id="PageContent" className="overflow-y-auto overflow-x-hidden ">
            <div className="flex py-0 mt-10 mx-10">
              <h5 className="text-2xl pb-4 font-bold leading-none text-gray-700 ">Simulation Status</h5>
              <div className="group">
                <svg
                  data-popover-target="chart-info"
                  data-popover-placement="bottom"
                  className="w-3.5 h-3.5 text-blue-500 hover:text-blue-900 cursor-pointer ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm0 16a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm1-5.034V12a1 1 0 0 1-2 0v-1.418a1 1 0 0 1 1.038-.999 1.436 1.436 0 0 0 1.488-1.441 1.501 1.501 0 1 0-3-.116.986.986 0 0 1-1.037.961 1 1 0 0 1-.96-1.037A3.5 3.5 0 1 1 11 11.466Z" />
                </svg>
                <div
                  id="chart-info"
                  className="absolute z-10 inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 transform scale-0 transform-origin-top-left group-hover:opacity-100 group-hover:scale-100 w-72"
                >
                  <div className="p-3 space-y-2">
                    <h3 className="font-semibold text-gray-900">Simulation - Status</h3>
                    <p>
                      A phishing simulation graph visually presents key data and insights from the simulation in a
                      concise manner. It aids in understanding success rates, vulnerable areas, user behavior, and
                      overall security posture, allowing quick identification of strengths and weaknesses to enhance
                      cybersecurity measures.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="mb-10">
                <LineChart />
              </div>
              <div className="mb-10 flex justify-center items-center text-center">
                <RadialChart />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar/>
    </div>
  )
}

export default Charts

