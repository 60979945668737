import React, { useState, useEffect, useRef, useMemo } from "react"
import axios from "axios"
import Dropzone from "react-dropzone-uploader"
import SignatureCanvas from 'react-signature-canvas'
import { useAuth } from "../utils/auth"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css" // Import toastify styles
import config from "../config/config"
import Sidebar from "../components/Sidebar"
import Quill from "quill"
import Editor from "../components/Editor"
import Popup from "../components/Popup"

const Delta = Quill.import("delta")
const UPLOADTEMPLATES_URL = `${config.apiBaseUrl}S3/upload`

function Template() {
  const [name, setName] = useState("")
  const [image, setImage] = useState(null)
  const editor = useRef()
  const [content, setContent] = useState("")
  const sigCanvas = useRef()
  const [isSigCanvasOpen, setIsSigCanvasOpen] = useState(false)
  const [signatureURL, setSignatureURL] = useState("")
  const [postRequestToggle, setPostRequestToggle] = useState(false)
  const [isPopupOpen, setIsPopOpen] = useState(false)
  const auth = useAuth()

  // useEffect(() => {
  //   console.log(`Editor content - ${content}`)
  // }, [content])

  const openPopup = () => {
    setIsPopOpen(true)
  }
  const closePopup = () => {
    setIsPopOpen(false)
  }

  const openSigCanvas = () => {
    setIsSigCanvasOpen(true)
  }
  const closeSigCanvas = () => {
    setIsSigCanvasOpen(false)
  }
  const saveSignature = () => {
    const signatureData = sigCanvas.current.toDataURL()
    console.log(signatureData)
    setSignatureURL(signatureData)
    closeSigCanvas()
  }

  const sanitizeContent = (content) => {
    const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
    return content.replace(scriptRegex, "")
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleImageChange = (file) => {
    if (file.size > 100000) {
      toast.error("Image size should be less than 100KB")
      setImage(null)
    } else {
      setImage(file)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (content === "" || !image || !name.trim()) {
      toast.error("Please enter all required fields")
      return
    }

    try {
      const sanitizedContent = sanitizeContent(content)
      const formData = new FormData()
      formData.append("image", image)
      formData.append("htmlContent", sanitizedContent)
      formData.append("name", name)
      formData.append("signatureURL", signatureURL)

      const response = await axios.post(UPLOADTEMPLATES_URL, formData, {
        headers: { authorization: auth.user.token },
      })

      if (response.status === 200) {
        toast.success("Template added successfully!")
        setName("")
        setImage(null)
        setContent("")
        setPostRequestToggle(!postRequestToggle)
      } else {
        toast.error("Failed to add template")
      }
    } catch (err) {
      console.error(err)
      toast.error("An error occurred while adding the template")
    }
  }

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} /> {/* Toast Notifications */}
      <div className="flex">
        <div className="flex-none w-1/6">
          <Sidebar />
        </div>
        <div className="w-4/5 flex flex-col p-6 justify-center align-center">
          <h2 className="text-3xl pt-10 pb-2 px-2 mb-8 font-bold leading-none text-gray-700">Add Email Template</h2>
          <div className="bg-white shadow-md rounded-lg border-2 border-gray-200 p-6">
            <div className="flex justify-between items-center mb-6">
              <div className="flex-grow mr-4">
                <label htmlFor="name" className="block text-lg font-medium text-gray-900 mb-2">
                  Template Name <span className="text-xl text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  pattern="[A-Za-z]{3,}"
                  onChange={handleNameChange}
                  value={name}
                  required
                  className="w-full p-1 border-blue-600 border-2 rounded-lg bg-gray-50 text-gray-900 focus:ring-primary-600 focus:border-primary-600"
                />
              </div>
              <div>
                <button
                  className="mt-9 text-white bg-blue-600 font-semibold text-sm text-center px-4 py-2 rounded-lg border-2 border-blue-600 focus:ring-2 focus:ring-primary-200 hover:bg-blue-800 flex items-center"
                  onClick={openPopup}
                >
                  Add image
                </button>
                <Popup isOpen={isPopupOpen} onClose={closePopup}>
                  <h2 className="text-xl font-bold mb-4">Upload Template Image</h2>
                  <p className="text-gray-700"> Image size should be less than 100KB. </p>
                  <div className="mb-4">
                    <Dropzone
                      onChangeStatus={({ meta, file }, status) => {
                        if (status === "done") {
                          handleImageChange(file)
                        } else if (status === "removed") {
                          setImage(null)
                        }
                      }}
                      accept="image/*"
                      maxFiles={1}
                      inputContent={() => (
                        <div style={{ fontSize: "18px" }}>
                          Drag and drop an image file or Browse files
                        </div>
                      )}
                      styles={{
                        dropzone: {
                          margin: "20px 5px 0 5px",
                          border: "3px dashed #78c4ff",
                          borderRadius: "4px",
                          backgroundColor: "#f7fafc",
                          padding: "20px",
                          textAlign: "center",
                          overflowY: "hidden",
                          overflowX: "hidden",
                          scrollbarWidth: "none",
                          msOverflowStyle: "none",
                        },
                        dropzoneActive: { borderColor: "#4CAF50" },
                      }}
                    />
                  </div>
                  <button
                    className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                </Popup>
              </div>
              <div>
                <button
                  className="ml-3 mt-9 text-white bg-blue-600 font-semibold text-sm text-center px-4 py-2 rounded-lg border-2 border-blue-600 focus:ring-2 focus:ring-primary-200 hover:bg-blue-800 flex items-center"
                  onClick={openSigCanvas}
                >
                  Add Signature
                </button>
                <Popup isOpen={isSigCanvasOpen} onClose={closeSigCanvas}>
                  <h2 className="text-xl font-bold mb-4">Add Signature</h2>
                  <SignatureCanvas
                    ref={sigCanvas}
                    canvasProps={{ width: 400, height: 150, backgroundColor: "white" }}
                    // onSave={(signature) => {
                    //   const signatureData = signature.toDataURL("image/png")
                    //   setContent(content + `<img src="${signatureData}" alt="signature" />`)
                    //   closeSigCanvas()
                    // }}
                  />
                  <button
                    className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
                    onClick={() => {sigCanvas.current.clear()}}>
                    Clear
                  </button>
                  <button
                    className="ml-5 mt-4 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
                    onClick={saveSignature}>
                    Save
                  </button>
                </Popup>
              </div>
            </div>
            <div className="w-full mb-6">
              <Editor
                ref={editor}
                value={content}
                placeholder="Start typing here...."
                onChange={(e) => setContent(e)}
              />
            </div>
            <div className="text-center">
              <button
                className="inline-flex items-center px-5 py-2.5 text-sm font-semibold text-white bg-blue-700 rounded-lg focus:ring-2 focus:ring-primary-200 hover:bg-blue-800"
                id="upload"
                name="upload"
                onClick={handleSubmit}
              >
                Add Template
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
    </>
  )
}

export default Template
