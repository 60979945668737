import { useState } from "react"
import axios from "axios"
import config from "../config/config"
import { useAuth } from "../utils/auth"
import Modal from "../components/Modal"
import { useNavigate } from "react-router-dom"
import Sidebar from "../components/Sidebar"
import Navbar from "../components/Navbar"

const TwoFactorAuth = () => {
  const auth = useAuth()
  const seturl = `${config.apiBaseUrl}twofactor/setup`
  const verifyurl = `${config.apiBaseUrl}twofactor/verify`
  const [qrCodeUrl, setQrCodeUrl] = useState("")
  const [secret, setSecret] = useState("")
  const [token, setToken] = useState("")
  const [message, setMessage] = useState("")
  const [isModalVisible, setIsModalVisible] = useState(false)
  const navigate = useNavigate()

  // Generate the QR Code and secret
  const generate2FA = async () => {
    try {
      const response = await axios.post(seturl, null, {
        headers: {
          authorization: auth.user.token,
        },
      })
      setQrCodeUrl(response.data.qrCodeUrl)
      setSecret(response.data.secret)
      setMessage("Scan the QR code with your authenticator app.")
      setIsModalVisible(true)
    } catch (error) {
      console.error("Error generating 2FA:", error)
      setMessage("Failed to generate 2FA.")
    }
  }

  // Verify the TOTP token
  const verify2FA = async () => {
    try {
      const response = await axios.post(verifyurl, {
        body: { token },
        headers: {
          authorization: auth.user.token,
        },
      })
      setMessage(response.data.message)
      setIsModalVisible(false)
      navigate("/")
    } catch (error) {
      console.error("Error verifying 2FA:", error)
      setMessage("Invalid token. Please try again.")
    }
  }

  return (
    <div className="flex">
      <div className="w-1/6">
        <Sidebar />
      </div>
      <div className="flex flex-grow relative flex-col">
        <Navbar />
        <div id="PageContent" className="pt-30 mt-10 px-8">
          <div className="overflow-y-auto overflow-x-hidden">
            <h2 className="mx-3 text-3xl pt-10 pb-6 font-bold leading-none text-gray-700">Two Factor Authentication</h2>
            <div className="bg-white rounded-lg shadow-md p-6">
              <p className="text-gray-600 mb-6">
                Enhance your account security by setting up two-factor authentication.
              </p>
              <button
                onClick={generate2FA}
                className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out"
              >
                Setup 2FA
              </button>

              {message && <p className="mt-6 text-blue-700 font-bold">{message}</p>}

              {/* Modal for QR code and TOTP input */}
              <Modal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)}>
                <div className="text-center">
                  <p className="mb-4">{message}</p>
                  {qrCodeUrl && (
                    <img
                      src={qrCodeUrl || "/placeholder.svg"}
                      alt="QR Code"
                      className="mb-6 mx-auto border-4 border-white rounded-lg"
                    />
                  )}
                  <input
                    type="text"
                    placeholder="Enter TOTP"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    className="mb-4 mr-4 p-3 text-blue-700 bg-white rounded-lg border-2 border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-300"
                  />
                  <button
                    onClick={verify2FA}
                    className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-300 ease-in-out"
                  >
                    Verify 2FA
                  </button>
                </div>
              </Modal>
            </div>
            <button
              onClick={() => navigate("/")}
              className="mt-6 px-6 py-3 bg-gray-200 text-gray-700 font-semibold rounded-lg hover:bg-gray-300 transition duration-300 ease-in-out"
            >
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TwoFactorAuth