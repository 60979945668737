import React, { forwardRef, useEffect, useLayoutEffect, useRef } from "react"
import Quill from "quill"
import "quill/dist/quill.snow.css" // For Snow theme
// import 'quill/dist/quill.bubble.css'; // Optional: For Bubble theme

// Editor is an uncontrolled React component
const Editor = forwardRef(({ readOnly, defaultValue, onTextChange, onSelectionChange, onChange }, ref) => {
  const containerRef = useRef(null)
  const defaultValueRef = useRef(defaultValue)
  const onChangeRef = useRef(onChange)
  const onTextChangeRef = useRef(onTextChange)
  const onSelectionChangeRef = useRef(onSelectionChange)

  useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange
    onSelectionChangeRef.current = onSelectionChange
    onChangeRef.current = onChange
  })

  useEffect(() => {
    ref.current?.enable(!readOnly)
  }, [ref, readOnly])

  useEffect(() => {
    const container = containerRef.current
    const editorContainer = container.appendChild(container.ownerDocument.createElement("div"))
    const quill = new Quill(editorContainer, {
      theme: "snow",
    })

    ref.current = quill

    if (defaultValueRef.current) {
      quill.setContents(defaultValueRef.current)
    }

    quill.on(Quill.events.TEXT_CHANGE, (...args) => {
      const contents = quill.root.innerHTML
      onChangeRef.current?.(contents)
      onTextChangeRef.current?.(...args)
    })

    quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
      onSelectionChangeRef.current?.(...args)
    })

    return () => {
      ref.current = null
      container.innerHTML = ""
    }
  }, [ref])

  return <div ref={containerRef} className="h-[300px] rounded-lg overflow-hidden border-2"></div>
})

Editor.displayName = "Editor"

export default Editor