import React from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import CampaignUserFilters from "../components/CampaignUserFilters";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import config from "../config/config";
import axios from "axios";
import { useAuth } from "../utils/auth";

const DOMAINS_URL = `${config.apiBaseUrl}get/domains`;

function CampaignDetails() {
    const auth = useAuth();
    const { uuid, id } = useParams();
    const [campaignDetails, setCampaignDetails] = useState({
        emailTemplateId: id,
        campaignSubject: "",
        campaignEnvName: "",
        campaignEnvelopeEmailId: "",
        emailTemplateUUID: uuid,
        campaignName: "",
        domainId: "",
    });

    const [domain, setDomain] = useState({});
    const [domainList, setDomainList] = useState([]);

    const fetchDomains = async () => {
        try {
            const res = await axios({
                method: "GET",
                url: DOMAINS_URL,
                headers: {
                    authorization: auth.user.token,
                },
            });

            const data = res.data;
            if (data.domains.length > 0) {
                setDomainList([
                    { domainId: 0, domainName: "Select Domain" },
                    ...data.domains,
                ]);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        fetchDomains();
    }, []);

    const handleChange = (e) => {
        setCampaignDetails({
            ...campaignDetails,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="flex h-screen">
            {/* Sidebar */}
            <div className="w-64 h-full">
                <Sidebar />
            </div>

            {/* Main Content */}
            <div className="flex-grow flex flex-col">
                {/* Navbar */}
                <Navbar />

                {/* Page Content */}
                <div
                    id="PageContent"
                    className="pt-20 px-8 overflow-y-auto flex-grow"
                >
                    <div className="max-w-4xl mx-auto bg-white rounded-lg p-8">
                        <h2 className="mb-8 text-3xl font-bold text-gray-700 text-center">
                            Campaign Details
                        </h2>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Campaign Name */}
                            <div className="flex flex-col">
                                <label
                                    htmlFor="campaignName"
                                    className="text-base font-medium text-gray-700">
                                    Campaign Name
                                    <span className="ml-1 text-xs text-gray-500">
                                        : Not visible to recipients
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    name="campaignName"
                                    id="campaignName"
                                    className="p-3 mt-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-300 ease-in-out"
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Subject Name */}
                            <div className="flex flex-col">
                                <label
                                    htmlFor="campaignSubject"
                                    className="text-base font-medium text-gray-700"
                                >
                                    Subject Name
                                    <span className="ml-1 text-xs text-gray-500">
                                        : The subject line of the email
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    name="campaignSubject"
                                    id="campaignSubject"
                                    className="p-3 mt-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-300 ease-in-out"
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Envelope ID */}
                            <div className="flex flex-col">
                                <label
                                    htmlFor="campaignEnvelopeEmailId"
                                    className="text-base font-medium text-gray-700"
                                >
                                    Envelope ID
                                    <span className="ml-1 text-xs text-gray-500">
                                        : Sender's email address without
                                        @domain.com
                                    </span>
                                </label>
                                <input
                                    type="email"
                                    name="campaignEnvelopeEmailId"
                                    id="campaignEnvelopeEmailId"
                                    className="p-3 mt-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-300 ease-in-out"
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Envelope Name */}
                            <div className="flex flex-col">
                                <label
                                    htmlFor="campaignEnvName"
                                    className="text-base font-medium text-gray-700"
                                >
                                    Envelope Name
                                    <span className="ml-1 text-xs text-gray-500">
                                        : Sender's Name (e.g., HR Team)
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    name="campaignEnvName"
                                    id="campaignEnvName"
                                    className="p-3 mt-2 border rounded-lg shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-300 ease-in-out"
                                    onChange={handleChange}
                                />
                            </div>

                            {/* Domain Selection */}
                            <div className="flex flex-col justify-center">
                                <label
                                    htmlFor="domainId"
                                    className="text-base font-medium text-gray-700"
                                >
                                    Domain Name
                                </label>
                                <select
                                    name="domainId"
                                    value={domain}
                                    onChange={(e) => {
                                        setDomain(e.target.value);
                                        handleChange(e);
                                    }}
                                    className="p-3 mt-2 border rounded-lg shadow-sm bg-white focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-300 ease-in-out"
                                >
                                    {domainList.map((domain) => (
                                        <option
                                            key={domain.domainId}
                                            value={domain.domainId}
                                        >
                                            {domain.domainName}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>



                        {/* Campaign User Filters */}
                        <div className="mt-12">
                            <CampaignUserFilters
                                campaignDetails={campaignDetails}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CampaignDetails;
