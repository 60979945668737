import React, { useState } from 'react'
import axios from 'axios'
import { useAuth } from "../utils/auth";
import { toast, ToastContainer } from "react-toastify"
import { useNavigate } from 'react-router'
import config from "../config/config";
import logo from "../PhishInstinct_Logo_black.png"

const FORGOT_PASS_URL = `${config.apiBaseUrl}forgotpass/forgot-password`;
const RESET_PASS_URL = `${config.apiBaseUrl}forgotPass/reset-password`;

function ForgotPass() {
  const auth = useAuth()
  const navigate = useNavigate()

  const [user, setUser] = useState({
    email: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
  })
  const [showOtpInput, setShowOtpInput] = useState(false)

  const handleForgotPass = async (e) => {
    e.preventDefault();

    if (!user.email || user.email === " ") {
      toast.error(`Please enter your email.`)
      return
    }

    try {
      const response = await axios({
        method: "post",
        url: FORGOT_PASS_URL,
        data: { ...user, email: user.email},
      })
      if (response.status === 200) {
        toast.success(`An OTP has been sent to your email.`)
        setShowOtpInput(true)
        // console.log(`Mail sent successfully. - ${JSON.stringify(response)}`)
      } else {
        toast.error(response.data.message)
        // console.log(`error in forgot password axios - ${JSON.stringify(response)}`)
      }
    } catch (error) {
      console.log(`Error occured for forgot password implementation: ${error}`)
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!showOtpInput) {
      toast.info(`Please submit your valid email fist to get an OTP.`)
      return
    }

    if (!user.email || user.email === " ") {
      toast.error(`Please enter your email.`)
      return
    }

    if (user.newPassword != user.confirmPassword) {
      toast.error(`Both the password fields should be same.`)
      return
    }

    try {
      const response = await axios({
        method: "post",
        url: RESET_PASS_URL,
        data: { email: user.email, otp: user.otp, newPassword: user.newPassword},
      })
      if (response.status === 200) {
        // console.log(`Password reset successfully - ${JSON.stringify(response)}`)
        toast.info(`Password updated successfully.`)
        navigate("/login")
      } else {
        toast.error(response.data.message)
        // console.log(`Reset password error response - ${JSON.stringify(response)}`)
      }
    } catch (error) {
      console.log(`Error for reset password implementation - ${error}`)
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  return (
    <>
      <div className="flex h-screen bg-white">
      <div className="w-1/2 flex justify-center items-center py-4">
        <div className="ml-10 mr-0 max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center">
            <a href="/" className="flex justify-center items-center">
              <img src='./login.gif' alt="forgot password page image" />
            </a>
          </div>
        </div>
      </div>

      <div className="w-1/2 flex justify-center items-center py-12">
        <div className="max-w-md w-full backdrop-blur-sm bg-white py-8 px-8 bg-neomorphism-gradient p-6 rounded-lg shadow-neomorphism border border-transparent">
          <div className="flex flex-col items-center justify-center">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <h2 className="mt-6 text-center text-2xl font-semibold leading-9 text-gray-900">Forgot Password</h2>
            </div>
            <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
              <form onSubmit={showOtpInput ? handleVerifyOtp : handleForgotPass} className="space-y-6">
                <div>
                  <label htmlFor="email" className="block text-lg font-semibold leading-6 text-gray-900">
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      placeholder="name@company.com"
                      autoComplete="email"
                      required
                      readOnly={showOtpInput}
                      className="block w-full rounded-md border-2 p-2 text-gray-900 focus:ring-2 focus:ring-primary-300 placeholder:text-gray-400 focus:outline-none focus:border-primary-500 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {showOtpInput && (
                  <>
                    <div>
                      <label htmlFor="otp" className="block text-lg font-semibold leading-6 text-gray-900">
                        OTP
                      </label>
                      <div className="mt-2">
                        <input
                          id="otp"
                          name="otp"
                          type="text"
                          onChange={handleChange}
                          placeholder="Enter 6-digit OTP"
                          required
                          maxLength={6}
                          className="block w-full rounded-md border-2 p-2 text-gray-900 focus:ring-2 focus:ring-primary-300 placeholder:text-gray-400 focus:outline-none focus:border-primary-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="newPassword" className="block text-lg font-semibold leading-6 text-gray-900">
                        New Password
                      </label>
                      <div className="mt-2">
                        <input
                          id="newPassword"
                          name="newPassword"
                          type="password"
                          onChange={handleChange}
                          placeholder="Enter new password"
                          required
                          className="block w-full rounded-md border-2 p-2 text-gray-900 focus:ring-2 focus:ring-primary-300 placeholder:text-gray-400 focus:outline-none focus:border-primary-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div>
                      <label htmlFor="confirmPassword" className="block text-lg font-semibold leading-6 text-gray-900">
                        Confirm Password
                      </label>
                      <div className="mt-2">
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          onChange={handleChange}
                          placeholder="Confirm new password"
                          required
                          className="block w-full rounded-md border-2 p-2 text-gray-900 focus:ring-2 focus:ring-primary-300 placeholder:text-gray-400 focus:outline-none focus:border-primary-500 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </>
                )}

                <div>
                  <button
                    type="submit"
                    className="w-full text-white bg-blue-700 hover:bg-[#3ABEF9] focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-md px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  >
                    {showOtpInput ? "Reset Password" : "Send OTP"}
                  </button>
                </div>
              </form>
              <div className="mt-4 text-center">
                <a href="/login" className="text-blue-700 hover:underline">
                  Back to Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        pauseOnFocusLoss
      />
    </div>
    </>
  )
}

export default ForgotPass