import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faUserPlus,
  // faFileCsv,
  // faCircleArrowLeft,
  // faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../utils/auth";
import axios from "axios";
import Modal from "../components/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";
import { useNavigate } from "react-router-dom";

const GET_ALL_ADMINS_URL = `${config.apiBaseUrl}adminroute/admins`;
const admin_url = `${config.apiBaseUrl}get/admin`
const CREATE_ADMIN_URL = `${config.apiBaseUrl}adminroute/create-admin`
// const CREATE_URL = `${config.apiBaseUrl}user/createUsers`;
// const DELETE_URL = `${config.apiBaseUrl}user/deleteUsers`;
// const DELETE_ALL_URL = `${config.apiBaseUrl}user/deleteAllUsers`;
// const CSV_USERS_URL = `${config.apiBaseUrl}user/addUsers`;


//UserTable component
function AdminList() {
  const auth = useAuth();
  const navigate = useNavigate()
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [admin, setAdmin] = useState({});
  const [formData, setFormData] = useState({
    adminName: "",
    adminEmail: "",
    adminPassword: "",
    adminHost: "",
    adminSender: "",
    adminSenderPassword: "",
    adminPort: "",
    // token: "",
    userLimit: "",
    // role
  })
  const [chunkId, setChunkId] = useState(1);
  const [userCount, setUserCount] = useState(0);
  const [chunkSize, setChunkSize] = useState(1);
  // const [csvModal, setCsvModal] = useState(false);
  // const [csvFile, setCsvFile] = useState(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const fetchAdmin = async () => {
    try {
      const res = await axios.get(admin_url, {
        headers: {
          authorization: auth.user.token,
        },
      });
      const data = res.data;
      if (data) {
        setAdmin(data);

      }
    } catch (e) {
      console.log(e);
    }
  };

  //initialization call
  const fetchAdmins = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: GET_ALL_ADMINS_URL,
        headers: {
          authorization: auth.user.token,
        }
      });
      const data = res.data;
      if (data.pages !== -1) {
        setChunkSize(data.pages);
      }
      setUsers(res.data);
      setUserCount(data.count);
    } catch (e) {
      toast.error(e.message || 'An Error Occured , Please Try Again');
    }
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  useEffect(() => {
    if (admin) { // Only call fetchAdmins if admin is set
      fetchAdmins();
    }
    // setCsvModal(null);
  }, [chunkId, admin]); // Add admin as a dependency

  const handleAdminDelete = async (adminId) => {
    if (window.confirm("Are you sure you want to delete this admin? This action is non-reversible.")) {
      try {
        const response = await axios.delete(`${config.apiBaseUrl}adminroute/admin/${adminId}`, {
          headers: {
            authorization: auth.user.token,
          },
        });
        if (response.status === 200) {
          toast.success("Admin deleted successfully!");
          fetchAdmins(); // Refresh the user list after deletion
        }
      } catch (error) {
        toast.error(error.message || "An error occurred. Please try again.");
      }
    }
  };

  const handleAdminClick = (adminId) => {
    navigate(`/admin/${adminId}/users`)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await axios({
        method: "POST",
        url: CREATE_ADMIN_URL,
        data: formData,
        headers: {
          authorization: auth.user.token,
        },
      })
      if (response.status === 200) {
        toast.success(`Admin created successfully`)
        setShowModal(false)
        // console.log(`admin created - ${JSON.stringify(response)}`)
        fetchAdmins();
      }
    } catch (error) {
      console.log(`Erro creating an admin - ${error}`)
      toast.error(error.message)
      setShowModal(false)
    }
  }

  return (
    <>
      <div className="px-6 py-0 my-4">
        <div className="w-full place-center relative overflow-x-auto shadow-md sm:rounded-lg content-center m-2">
          <div className="flex flex-row-reverse space-x-3 items-center">
            <button onClick={() => setShowModal(true)} className="mx-6">
              <FontAwesomeIcon icon={faUserPlus} size="xl" />
            </button>
            {/* <div className="px-3 ml-auto">
            </div>
            <div className="px-3">
            </div>
            <div className="px-3">
              <button onClick={() => setCsvModal(true)}>
                <FontAwesomeIcon icon={faFileCsv} size="2xl" />
              </button> 
            </div> */}
          </div>
          <table className="w-full text-sm text-left text-gray-500 m-2 p-2">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-4 py-3">
                  Name
                </th>
                <th scope="col" className="px-4 py-3">
                  Email
                </th>
                <th scope="col" className="px-4 py-3">
                  UserLimit
                </th>
                <th scope="col" className="px-4 py-3">
                  Role
                </th>
                {/* <th scope="col" className="px-4 py-3">
                  Group
                </th> */}
                <th scope="col" className="px-4 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* <UserData users={users}/> */}
              {users.map((curUser) => {
                return (
                  <tr
                    key={curUser.id}
                    className="bg-white border-b hover:bg-gray-50"
                  >

                    <td onClick={() => handleAdminClick(curUser.adminId)} className="px-3 py-4">{curUser.adminName}</td>
                    <td className="px-3 py-4">{curUser.adminEmail}</td>
                    <td className="px-3 py-4">{curUser.userLimit}</td>
                    <td className="px-3 py-4">{curUser.role}</td>
                    {/* <td className="px-3 py-4">{curUser.group}</td> */}
                    <td className="px-7 py-4">
                      <button onClick={() => handleAdminDelete(curUser.adminId)}>
                        <FontAwesomeIcon icon={faTrashCan} size="xl" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Modal isVisible={showModal} onClose={() => {
            setShowModal(false);
          }}>

            <div className="py-0 px-4 mx-auto max-w-2xl lg:py-4">
              <h2 className="mb-4 text-xl font-bold text-gray-900">
                Add a new admin:
              </h2>
              <form onSubmit={(e) => handleSubmit(e)} className="max-w-lg mx-auto p-6 bg-white rounded-lg">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 ">
                  <div>
                    <label className="block mb-2 text-lg font-semibold text-gray-900">
                      Admin Name:
                    </label>
                    <input
                      type="text"
                      name="adminName"
                      value={formData.adminName}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-lg font-semibold text-gray-900">
                      Admin Email:
                    </label>
                    <input
                      type="email"
                      name="adminEmail"
                      value={formData.adminEmail}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-lg font-semibold text-gray-900">
                      Admin Password:
                    </label>
                    <input
                      type="password"
                      name="adminPassword"
                      value={formData.adminPassword}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-lg font-semibold text-gray-900">
                      Admin Host:
                    </label>
                    <input
                      type="text"
                      name="adminHost"
                      value={formData.adminHost}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-lg font-semibold text-gray-900">
                      Sender:
                    </label>
                    <input
                      type="text"
                      name="adminSender"
                      value={formData.adminSender}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-lg font-semibold text-gray-900">
                      Sender Password:
                    </label>
                    <input
                      type="password"
                      name="adminSenderPassword"
                      value={formData.adminSenderPassword}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-lg font-semibold text-gray-900">
                      Admin Port:
                    </label>
                    <input
                      type="text"
                      name="adminPort"
                      value={formData.adminPort}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-lg font-semibold text-gray-900">
                      User Limit:
                    </label>
                    <input
                      type="number"
                      name="userLimit"
                      value={formData.userLimit}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 transition duration-150 ease-in-out"
                    />
                  </div>
                  <button
                    type="submit"
                    className="w-full text-white bg-green-600 hover:bg-green-900 focus:ring-green-500 focus:border-green-500 font-medium rounded-lg text-m transition duration-150 ease-in-out justify-center items-center py-3 mt-4"
                  >
                    Add User
                  </button>
                </div>
              </form>

            </div>
          </Modal>
        </div>
      </div>
      <ToastContainer autoClose={3000} position="top-center" hideProgressBar={true} />
    </>
  );
}

export default AdminList;
