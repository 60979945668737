import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faBullhorn,
  faUsers,
  faTableColumns,
  faSignOutAlt,
  faUserShield,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import img from "./PhishInstinct_black.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/auth";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../config/config";
import { RiAdminFill } from "react-icons/ri";


function Sidebar() {
  const [admin, setAdmin] = useState({});
  const admin_url = `${config.apiBaseUrl}get/admin`;



  const navigate = useNavigate();
  const auth = useAuth();

  const handleLogout = () => {
    auth.logout();
    navigate("/login");
  };

  const fetchAdmin = async () => {
    try {
      const res = await axios.get(admin_url, {
        headers: {
          authorization: auth.user.token,
        },
      });

      const data = res.data;
      // console.log(data)

      if (data) {
        setAdmin(data);
        // console.log(data);
      }
    } catch (error) {

      toast.error("Failed to fetch admin details");
    }
  };

  useEffect(() => {
    if (auth.user && auth.user.token) {
      fetchAdmin();
    }
  }, [auth.user]);

  const Options = [
    { title: "Dashboard", icon: faTableColumns, link: "/" },
    { title: "Analytics", icon: faChartBar, link: "/charts" },
    { title: "Campaign", icon: faBullhorn, link: "/campaign" },
    { title: "Users", icon: faUsers, link: "/users" },
    { title: "Create Template", icon: faPlus, link: "/template" }, 
    ...(admin.role === "super_admin"
      ? [{ title: "Admins", icon: faUserShield, link: "/admin" }]
      : []),
  ];


  return (
    <div className="bg-gray-100 h-screen w-1/6 fixed flex flex-col items-center text-center p-4 mr-2 overflow-y-auto">
      <NavLink to="/">
        <div className="w-full">
          <img
            src={img}
            alt="PhishInstinctLogo"
            className="w-full h-auto mb-10"
          />
          <hr className="mb-10 mt-5 text-gray-700"></hr>
        </div>
      </NavLink>
      <h1 className="text-lg md:text-md lg:text-md text-gray-900 font-semibold mb-6 md:mb-8 lg:mb-10 flex items-center">
        <span className="mr-2 md:mr-3">
          <RiAdminFill size={24} className="md:text-[26px] lg:text-[30px]" />
        </span>
        Admin Controls
      </h1>

      <ul className="flex-1 space-y-2 w-full">
        {Options.map((item, index) => (
          <li key={index}>
            <NavLink
              to={item.link}
              className={({ isActive }) =>
                `text-gray-900 mb-5 text-lg flex items-center cursor-pointer py-4 px-6 rounded-lg transition-transform transform hover:scale-105 hover:bg-gray-300 hover:backdrop-blur-md hover:border-gray-700 hover:shadow-neomorphism-hover duration-300 w-full ${isActive ? "bg-blue-700 text-white" : ""
                }`
              }
            >
              <FontAwesomeIcon icon={item.icon} className="mr-2" />
              <span>{item.title}</span>
            </NavLink>
            <hr className="text-gray-900" />

          </li>
        ))}
      </ul>
      <button
        onClick={handleLogout}
        className="text-gray-900 text-lg flex items-center cursor-pointer py-4 px-6 rounded-md transition-transform transform hover:scale-105 hover:bg-red-600 mt-auto w-full"
      >
        <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
        <span>Logout</span>
      </button>
    </div>

  );
}

export default Sidebar;
