import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrashCan,
    faCircleArrowLeft,
    faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../utils/auth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";
import { useParams } from "react-router-dom";

function AdminUsers() {
    const [users, setUsers] = useState([]);
    const [chunkId, setChunkId] = useState(1);
    const [chunkSize, setChunkSize] = useState(1);
    const auth = useAuth();
    const { adminId } = useParams();
    const {userId} = useParams();
    const USERS_URL = `${config.apiBaseUrl}adminroute/admin/${adminId}/users`;
    const delete_URL = `${config.apiBaseUrl}adminroute/user/${userId}`;

    const fetchUser = async () => {
        try {
            const res = await axios({
                method: "GET",
                url: USERS_URL,
                headers: {
                    authorization: auth.user.token,
                },
            });
            const data = res.data;
            if (data.pages !== -1) {
                setChunkSize(data.pages);
            }
            setUsers(data);
        } catch (error) {
            toast.error(error.message || "An Error Occurred, Please Try Again");
        }
    };

    const deleteUser = async (userId) => {
        try {
            await axios({
                method: "DELETE",
                url: `${config.apiBaseUrl}adminroute/user/${userId}`,
                headers: {
                    authorization: auth.user.token,
                },
            });
            // After deletion, refetch users
            toast.success("User deleted successfully!");
            fetchUser(); // Re-fetch users to update the list
        } catch (error) {
            toast.error(
                error.message || "Error deleting user, please try again"
            );
        }
    };

    useEffect(() => {
        fetchUser();
    }, [chunkId]); // Only depend on chunkId for fetching users

    return (
        <>
            <div className="px-6 py-0 my-4">
                <div className="w-full place-center relative overflow-x-auto shadow-md sm:rounded-lg content-center m-2">
                    <table className="w-full text-sm text-left text-gray-500 m-2 p-2">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" className="px-4 py-3">
                                    Name
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Email
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Department
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Location
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((curUser) => (
                                <tr
                                    key={curUser.id}
                                    className="bg-white border-b hover:bg-gray-50"
                                >
                                  
                                    <td className="px-3 py-4">
                                        {curUser.userName}
                                    </td>
                                    <td className="px-3 py-4">
                                        {curUser.userEmail}
                                    </td>
                                    <td className="px-3 py-4">
                                        {curUser.userDepartment}
                                    </td>
                                    <td className="px-3 py-4">
                                        {curUser.userRegion}
                                    </td>
                                    <td className="px-7 py-4">
                                        <button
                                            onClick={() =>
                                                deleteUser(curUser.id)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                size="xl"
                                            />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex ">
                    <div className="flex space-x-3 items-center px-3">
                        <div>Page:</div>
                        <div>
                            <button
                                onClick={() => {
                                    if (chunkId > 1) {
                                        setChunkId(chunkId - 1);
                                    }
                                }}
                                disabled={chunkId <= 1}
                            >
                                <FontAwesomeIcon
                                    icon={faCircleArrowLeft}
                                    size="xl"
                                />
                            </button>
                        </div>
                        <div className="font-medium text-gray-500 whitespace-nowrap">
                            {chunkId}/{chunkSize}
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    if (chunkId < chunkSize) {
                                        setChunkId(chunkId + 1);
                                    }
                                }}
                                disabled={chunkId >= chunkSize}
                            >
                                <FontAwesomeIcon
                                    icon={faCircleArrowRight}
                                    size="xl"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </div>
        </>
    );
}

export default AdminUsers;
