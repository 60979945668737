import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../utils/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";
import { useNavigate } from "react-router";
import Modal from ".//Modal";

const GETFILTERDATA = `${config.apiBaseUrl}get/filterData`;
const STARTCAMPAIGN_URL = `${config.apiBaseUrl}campaign/createAndstartCampaign`;

function CampaignUserFilters(campaignDetails) {
    const auth = useAuth();
    const navigate = useNavigate();

    const [filterData, setFilterData] = useState([]);
    const [requestObject, setRequestObject] = useState({
        all: false,
        departments: [],
        locations: [],
    });
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: GETFILTERDATA,
                headers: {
                    authorization: auth.user.token,
                },
            });

            setFilterData(response.data);
        } catch (error) {
            toast.error("Error fetching filter data:", error);
        }
    };

    const createAndStartCampaign = async () => {
        setLoading(true);
        try {
            const data = {
                campaignDetails: campaignDetails.campaignDetails,
                filters: requestObject,
            };
            console.log(data);
            console.log(`${JSON.stringify(data)}`);
            const response = await axios({
                method: "POST",
                url: STARTCAMPAIGN_URL,
                headers: {
                    authorization: auth.user.token,
                },
                data: data,
            });
            if (response.status === 200) {
                toast.success("Emails sent!");
                setTimeout(() => navigate("/"), 1000);
            } else {
                toast.error(
                    `Error : ${response.body}! Please try again later.`
                );
            }
        } catch (error) {
            toast.error(error);
        }
        setLoading(false);
    };

    const handleFilterChange = (e) => {
        const { name, checked, value } = e.target;
        if (
            name === "all" ||
            (filterData.locations === requestObject.locations &&
                filterData.departments === requestObject.departments)
        ) {
            setRequestObject((prevRequestObject) => ({
                ...prevRequestObject,
                all: !prevRequestObject.all,
            }));
        } else {
            if (checked) {
                // Checkbox is checked, add to the corresponding array
                if (name === "locations") {
                    setRequestObject((prevRequestObject) => ({
                        ...prevRequestObject,
                        locations: [...prevRequestObject.locations, value],
                    }));
                } else if (name === "departments") {
                    setRequestObject((prevRequestObject) => ({
                        ...prevRequestObject,
                        departments: [...prevRequestObject.departments, value],
                    }));
                }
            } else {
                // Checkbox is unchecked, remove from the corresponding array
                if (name === "locations") {
                    setRequestObject((prevRequestObject) => ({
                        ...prevRequestObject,
                        locations: prevRequestObject.locations.filter(
                            (location) => location !== value
                        ),
                    }));
                } else if (name === "departments") {
                    setRequestObject((prevRequestObject) => ({
                        ...prevRequestObject,
                        departments: prevRequestObject.departments.filter(
                            (department) => department !== value
                        ),
                    }));
                }
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    loading
        ? (document.body.style.overflow = "hidden")
        : (document.body.style.overflow = "auto");

    return (
        <div className="flex justify-center items-center flex-col mb-8">
            <div className="p-8 bg-white rounded-lg max-w-2xl w-full">
                <h3 className="text-3xl font-extrabold mb-6 text-center text-gray-800">
                    Select Target User Groups
                </h3>
                <div className="flex justify-center items-center mb-6">
                    <div className="flex items-center px-4 py-3 bg-gray-200 rounded-md shadow-md">
                        <input
                            type="checkbox"
                            name="all"
                            id="all"
                            value="all"
                            checked={requestObject.all}
                            onChange={(e) => handleFilterChange(e)}
                            className="mr-3 h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label
                            htmlFor="all"
                            className="text-lg font-medium text-gray-700"
                        >
                            Send to all
                        </label>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mb-6">
                    {Object.keys(filterData).map((filterType) => (
                        <div
                            key={filterType}
                            className="p-4 bg-gray-50 rounded-lg shadow-md"
                        >
                            <label className="block text-lg font-semibold mb-3 text-gray-700">
                                {`Select by ${String(filterType)}:`}
                            </label>
                            <select
                                id={filterType}
                                name={filterType}
                                onChange={(e) => handleFilterChange(e)}
                                className="block w-full p-3 text-gray-700 bg-white border border-gray-300 rounded-lg focus:border-blue-500 focus:ring-blue-500"
                            >
                                <option value="">Select an option</option>
                                {filterData[filterType].map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center">
                    <button
                        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg shadow-lg transition duration-200 ease-in-out transform hover:scale-105"
                        onClick={() => {
                            createAndStartCampaign();
                        }}
                    >
                        Create and Send Campaign
                    </button>
                </div>
            </div>

            <div id="ModalContainer" className="overflow-hidden">
                <Modal isVisible={loading}>
                    <div className="py-4 mx-auto max-w-2xl">
                        <div className="flex flex-col items-center justify-center p-8 bg-white rounded-lg shadow-lg text-center">
                            <h1 className="text-4xl font-bold text-gray-800">
                                Your campaign has been created!
                            </h1>
                            <div className="flex space-x-2 py-10 animate-ping">
                                {[4, 6, 8, 10].map((size, index) => (
                                    <div
                                        key={index}
                                        className={`animate-bounce`}
                                    >
                                        <svg
                                            className={`h-${size} w-${size} text-red-500`}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                            <polyline points="22,6 12,13 2,6" />
                                        </svg>
                                    </div>
                                ))}
                            </div>
                            <p className="text-gray-600 text-xl mt-1">
                                Kindly wait while the emails are being
                                dispatched!
                            </p>
                        </div>
                    </div>
                </Modal>
            </div>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default CampaignUserFilters;
